import React from 'react';
import reglInit from 'regl';

// //import ReglView from "./ReglView"
// var canvas = React.createRef();
// var div = React.createRef();

// import reglInit from 'regl';


// const gl = canvas.getContext("webgl", {
//     alpha: false,
//     antialias: false,
//     stencil: false,
//     preserveDrawingBuffer: false
// });

// const regl = reglInit({ gl });
// regl.cache = {};

// // const regl = require('regl')({
// //     //gl: require('gl')(256, 256),
// //     container: div,
// //     extensions: ['oes_texture_float', 'angle_instanced_arrays']
// // });

// //const dat = require('dat.gui');


// var settings = {
//     particles: 100
// };

//let aspectRatio = 1.0;
let zoom = 1.0;

// let path = [];

// const width = 600; //window.innerWidth;
// const height = 600; //window.innerHeight;

// const aspectRatio = height / width;

const pointWidth = 1.0;

// const animationTickLimit = -1; // -1 disables
// if (animationTickLimit >= 0) {
//     console.log(`Limiting to ${animationTickLimit} ticks`);
// }

const sqrtNumParticles = 100;
const numParticles = sqrtNumParticles * sqrtNumParticles;
// console.log(`Using ${numParticles} particles`);

// // create a regl framebuffer holding the initial particle state
function createParticleBuffer(regl, particles) {
    // create a texture where R holds particle X and G holds particle Y position

    let state = new Float32Array(particles.length / 2 * 4);
    for (let i = 0; i < particles.length / 2; ++i) {
        // store x then y and then leave 2 spots empty
        var x = particles[i * 2];
        var y = particles[i * 2 + 1];
        state[i * 4] = x; // x position
        state[i * 4 + 1] = y;// y position
    }

    const initialTexture = regl.texture({
        data: state,
        shape: [sqrtNumParticles, sqrtNumParticles, 4],
        type: 'float'
    });

    // create a frame buffer using the state as the colored texture
    return regl.framebuffer({
        color: initialTexture,
        depth: false,
        stencil: false,
    });
}


// // create array of indices into the particle texture for each particle
const particleTextureIndex = [];
for (let i = 0; i < sqrtNumParticles; i++) {
    for (let j = 0; j < sqrtNumParticles; j++) {
        particleTextureIndex.push(i / sqrtNumParticles, j / sqrtNumParticles);
    }
}

// // regl command that draws particles at their current state
const drawParticles = (regl, currParticleState, aspectRatio) => regl({
    vert: `
        // set the precision of floating point numbers
        precision mediump float;

        uniform vec2 aspectRatio;
        attribute vec2 particleTextureIndex;
        uniform sampler2D particleState;

        // variables to send to the fragment shader
        varying vec3 fragColor;

        // values that are the same for all vertices
        uniform float pointWidth;

        void main() {
            // read in position from the state texture
            vec2 position = texture2D(particleState, particleTextureIndex).xy;

            // copy color over to fragment shader
            fragColor = vec3(abs(particleTextureIndex), 1.0);

            // scale to normalized device coordinates
            // gl_Position is a special variable that holds the position of a vertex
            gl_Position = vec4(position, 0.0, 1.0);

            // update the size of a particles based on the prop pointWidth
            gl_PointSize = pointWidth;
        }
	`,

    frag: `
        // set the precision of floating point numbers
        precision mediump float;

        // this value is populated by the vertex shader
        varying vec3 fragColor;

        void main() {
            // gl_FragColor is a special variable that holds the color of a pixel
            gl_FragColor = vec4(0, 0, 0, 1);
        }
    `,

    attributes: {
        // each of these gets mapped to a single entry for each of the points.
        // this means the vertex shader will receive just the relevant value for a given point.
        particleTextureIndex,
    },

    uniforms: {
        // important to use a function here so it gets the new buffer each render
        particleState: () => currParticleState,
        aspectRatio: [1, aspectRatio],
        pointWidth,
    },

    // specify the number of points to draw
    count: numParticles,

    // specify that each vertex is a point (not part of a mesh)
    primitive: 'points',

    // we don't care about depth computations
    depth: {
        enable: false,
        mask: false,
    },
});


const drawFunction = (regl, aspectRatio) => regl({
    vert: `
	precision highp float;
    attribute vec2 uv;
    uniform vec2 aspectRatio;
    uniform vec4 bounds;
    varying vec2 xy;
    uniform mat4 projection, view;

    float openAI(float x, float y) {
        float f = 1.0;
        float G1 = exp(-( pow((x-0.3),2.0)+pow((y+0.3),2.0) )/2.0*pow(4.0,2.0)*f );
        float G2 = exp(-( pow((x+0.3),2.0)+pow((y-0.3),2.0) )/2.0*pow(2.0,2.0)*f );
        float G3 = exp(-( pow((x-0.6),2.0)+pow((y-0.6),2.0) )/2.0*pow(2.0,2.0)*f );
        float G4 = exp(-( pow((x+0.4),2.0)+pow((y+0.2),2.0) )/2.0*pow(3.0,2.0)*f );
        float G = G1 + G2 - G3 - G4;
        return G;
      }

    void main () {
      xy = bounds.xz + (bounds.yw - bounds.xz) * (0.5 + 0.5 * uv.xy);
      gl_Position = vec4(uv* aspectRatio, 0.0, 1);
    }
	`,

    frag: `

  precision highp float;

  vec4 jet (float x) {
    const float e0 = 0.0;
    const vec4 v0 = vec4(0,0,0.5137254901960784,1);
    const float e1 = 0.125;
    const vec4 v1 = vec4(0,0.23529411764705882,0.6666666666666666,1);
    const float e2 = 0.375;
    const vec4 v2 = vec4(0.0196078431372549,1,1,1);
    const float e3 = 0.625;
    const vec4 v3 = vec4(1,1,0,1);
    const float e4 = 0.875;
    const vec4 v4 = vec4(0.9803921568627451,0,0,1);
    const float e5 = 1.0;
    const vec4 v5 = vec4(0.5019607843137255,0,0,1);
    float a0 = smoothstep(e0,e1,x);
    float a1 = smoothstep(e1,e2,x);
    float a2 = smoothstep(e2,e3,x);
    float a3 = smoothstep(e3,e4,x);
    float a4 = smoothstep(e4,e5,x);
    return max(mix(v0,v1,a0)*step(e0,x)*step(x,e1),
      max(mix(v1,v2,a1)*step(e1,x)*step(x,e2),
      max(mix(v2,v3,a2)*step(e2,x)*step(x,e3),
      max(mix(v3,v4,a3)*step(e3,x)*step(x,e4),mix(v4,v5,a4)*step(e4,x)*step(x,e5)
    ))));
  }

//   // From https://github.com/rreusser/glsl-solid-wireframe
//   /// Used to draw the contours on the plot
//   float contourFunction (float parameter, float lineWidth, float lineFeather) {
//     float w1 = lineWidth - lineFeather * 0.5;
//     float d = fwidth(parameter);
//     float looped = 0.5 - abs(mod(parameter, 1.0) - 0.5);
//     return smoothstep(d * w1, d * (w1 + lineFeather), looped);
//   }

  // The booth function - hacked in not changed function name
  // See: https://en.wikipedia.org/wiki/Test_functions_for_optimization
// Particular function used to demonstrate the code
  float goldsteinPriceFunction (float x, float y) {
    return ( x + 2.0 * y - 7.0 ) * ( x + 2.0 * y - 7.0 ) + ( 2.0 * x + y - 5.0 ) * ( 2.0 * x + y - 5.0 );
  }

  float openAI(float x, float y) {
    float f = .99;
    float G1 = exp(-( pow((x-0.3),2.0)+pow((y+0.3),2.0) )/2.0*pow(4.0,2.0)*f );
    float G2 = exp(-( pow((x+0.3),2.0)+pow((y-0.3),2.0) )/2.0*pow(2.0,2.0)*f );
    float G3 = exp(-( pow((x-0.6),2.0)+pow((y-0.6),2.0) )/2.0*pow(2.0,2.0)*f );
    float G4 = exp(-( pow((x+0.4),2.0)+pow((y+0.2),2.0) )/2.0*pow(3.0,2.0)*f );
    float G = G1 + G2 - G3 - G4;
    return G;
  }

  varying vec2 xy;
  uniform float ncontours;

  void main () {
    // Compute the function value
    //float f = goldsteinPriceFunction(xy.x, xy.y);
    float f = openAI(xy.x, xy.y);
    // Value ranges, for scaling the colors. Known a priori. Not easy to determine in general :(
    float min = -0.8695731357376953;
    float max = 0.9665630973201611;

    // The coloring!
    //float fScaledLog = (log(f) - logGlobalMinimum) / (logViewMaximum - logGlobalMinimum);
    float fScaledLog = (f-min)/(max-min);
    //float contour = contourFunction(fScaledLog * ncontours, 1.0, 1.0);
    vec4 color = jet(fScaledLog);

    // The output color
    gl_FragColor = vec4(color.rgb, 1);
  }
  `,

    attributes: {
        uv: [-4, -4, 0, 4, 4, -4]
    },
    uniforms: {
        aspectRatio: [1, aspectRatio],
        bounds: [-1 * zoom, 1 * zoom, -1 * zoom, 1 * zoom],
        ncontours: 4,
        color: [1, 0, 0, 1]
    },
    primitive: 'triangles',
    count: 3

});


function openAI(x, y) {
    var f = .99;
    var G1 = Math.exp(-(Math.pow((x - 0.3), 2.0) + Math.pow((y + 0.3), 2.0)) / 2.0 * Math.pow(4.0, 2.0) * f);
    var G2 = Math.exp(-(Math.pow((x + 0.3), 2.0) + Math.pow((y - 0.3), 2.0)) / 2.0 * Math.pow(2.0, 2.0) * f);
    var G3 = Math.exp(-(Math.pow((x - 0.6), 2.0) + Math.pow((y - 0.6), 2.0)) / 2.0 * Math.pow(2.0, 2.0) * f);
    var G4 = Math.exp(-(Math.pow((x + 0.4), 2.0) + Math.pow((y + 0.2), 2.0)) / 2.0 * Math.pow(3.0, 2.0) * f);
    var G = G1 + G2 - G3 - G4;
    return G;
}

function randn_bm(mu, sigma) {
    var u = 0, v = 0;
    while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
    while (v === 0) v = Math.random();
    return mu + sigma * Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
}

function standardDeviation(values) {
    var avg = average(values);

    var squareDiffs = values.map(function (value) {
        var diff = value - avg;
        var sqrDiff = diff * diff;
        return sqrDiff;
    });

    var avgSquareDiff = average(squareDiffs);

    var stdDev = Math.sqrt(avgSquareDiff);
    return stdDev;
}

function average(data) {
    var sum = data.reduce(function (sum, value) {
        return sum + value;
    }, 0);
    var avg = sum / data.length;
    return avg;
}

function randomParticles(numParticles, random, mu, sigma, height, width) {
    let particles = new Float32Array(numParticles * 2);
    for (let i = 0; i < numParticles; ++i) {
        // store x then y and then leave 2 spots empty
        var x = random(mu[0], sigma) * zoom;;
        var y = random(mu[1], sigma) * zoom;;
        particles[i * 2] = x / width * 2.0; // x position
        particles[i * 2 + 1] = y / height * 2.0 * zoom;// y position
    }
    return particles;
}

// console.log(height);
var mu = [0, 0];
var sigma = 40.0;
//var particles = randomParticles(numParticles, (mu, sigma) => randn_bm(mu, sigma), mu, sigma);
// let currParticleState = createParticleBuffer(particles);

function updateMean(particles, f) {
    let weights = new Float32Array(numParticles);
    for (let i = 0; i < numParticles; ++i) {
        var x = particles[i * 2];
        var y = particles[i * 2 + 1];

        weights[i] = f(x, y);
    }
    let std = standardDeviation(weights);
    let mean = average(weights);

    var sum_x = 0.0;
    var sum_y = 0.0;
    for (let i = 0; i < numParticles; ++i) {
        var x = particles[i * 2];
        var y = particles[i * 2 + 1];
        sum_x += (weights[i] - mean) / std * x;
        sum_y += (weights[i] - mean) / std * y;
    }
    return [sum_x, sum_y];
}

function update(particles, height, width) {
    var lr = 0.01;
    var g = updateMean(particles, openAI);
    g[0] *= lr;
    g[1] *= lr;
    mu[0] += g[0];
    mu[1] += g[1];
    //path.push([mu[0] / width * 2.0, mu[1] / height * 2.0])
    return randomParticles(numParticles, (mu, sigma) => randn_bm(mu, sigma), mu, sigma, height, width);
}






// const gui = new dat.GUI();
// gui.remember(settings);
// particlesController = gui.add(settings, 'particles').min(10).max(200).step(0.25);

// particlesController.onChange(function (value) {
//     Fires on every change, drag, keypress, etc.
//         sqrtNumParticles = Math.sqrt(value);
// });

// regl.frame(({ tick, viewportWidth, viewportHeight }) => {
//     drawFunction();
//     drawParticles();
//     particles = update(particles);
//     currParticleState = createParticleBuffer(particles);
// });

class Evo1 extends React.Component {
    regl = null
    tick = null;
    canvasRef = null;
    rootNode = null;

    state = { width: 0, height: 0 };

    componentWillUnmount() {

    }

    constructor(props, context) {
        super(props, context);
    }

    getChildContext() {
        if (this.context && this.context.store) {
            return { store: this.context.store };
        }
        return this.context;
    }

    updateCenter(e) {
        var target = e.target;
        var rect = target.getBoundingClientRect();

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        console.log(x, y);

        mu[0] = (x * zoom - this.width / 2.0);
        mu[1] = (this.height / 2.0 - y * zoom);
    }


    componentDidMount() {
        const canvasRef = this.props.canvas || this.canvasRef;
        const gl = canvasRef.getContext("webgl");

        const regl = reglInit({
            gl,
            extensions: ['oes_texture_float', 'angle_instanced_arrays']
        });
        regl.cache = {};
        this.regl = regl;

        canvasRef.addEventListener('click', this.updateCenter);


        let first_iter = true;
        let currParticleState = null;
        let particles = null;
        regl.frame(({ tick, viewportWidth, viewportHeight }) => {
            this.width = canvasRef.clientWidth;
            this.height = canvasRef.clientHeight;
            if (first_iter) {
                particles = randomParticles(numParticles, (mu, sigma) => randn_bm(mu, sigma), mu, sigma, this.height, this.width);
                currParticleState = createParticleBuffer(regl, particles);
                first_iter = false;
            }
            //console.log(canvasRef.clientWidth);
            const aspectRatio = this.width / this.height;
            drawFunction(regl, aspectRatio)();
            drawParticles(regl, currParticleState, aspectRatio)();
            particles = update(particles, this.height, this.width);
            currParticleState = createParticleBuffer(regl, particles);
        });
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        if (this.props.canvas) {
            return null;
        }

        return (
            <canvas ref={inst => { this.canvasRef = inst }} width='800' height='600' />
        )
    }
}
//<canvas ref={canvas} style={{ position: 'absolute', display: 'block', margin: '0', padding: '0', left: '0', backgroundColor: 'tomato', height: '300px', width: '100%' }} />

export default Evo1
